<script>
/*
this component displays panels based on configuration
 gridLayout is a composed array: 
   rows [ columns [  [panels] ] ]
  
   - column width are always in % - but it is not required
   - panels within same column are stacked 
*/
import EquipmentDashboardPanel from "@/components/equipment-dashboard-panel.vue";
import {debounceTime} from "@/utils";

const panelBottomMargin = 10;

export default {
  name: "DashboardContentBase",
  components: {
    EquipmentDashboardPanel
  },
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => ({})
    },
    screenId: {
      type: [String, Number],
      default: () => 0
    }
  },
  data() {
    return {
      busy: false,
      mountedPanels: {},
      dirty: false
    };
  },
  computed: {
    panels() {
      // expandedPanel is:
      // locally defined in the editor mode
      // and globally defined the viewer mode
      let valid = {};
      for (var ir in this?.display?.layout || []) {
        for (var ic in this.display.layout[ir]) {
          for (var ip in this.display.layout[ir][ic].panels) {
            valid[this.display.layout[ir][ic].panels[ip]] = true;
          }
        }
      }
      let lst = null;
      if (this.expandedPanel) {
        lst = [this.expandedPanel];
      } else {
        lst = (this?.display?.panels || [])
          .filter((panel) => {
            return panel.name in valid && this.can(panel);
          })
          .map((panel) => {
            return panel.name;
          });
      }
      return lst;
    },
    layout() {
      var self = this;
      var role = self.$store.getters["user/loggedUserRole"] || null;
      if (role && "layout" in role) {
        return role.layout;
      } else {
        if (self.display && "layout" in self.display) {
          return JSON.parse(JSON.stringify(self.display.layout)).filter(
            (cols) =>
              cols.filter((col) => {
                col.panels = col.panels.filter((panel) => {
                  let p = (self?.display?.panels || []).find(
                    (p) => p.name == panel
                  );
                  if (p?.view_permission?.length) {
                    return p.view_permission.some((id) =>
                      this.$store.getters["user/hasUserAccessTo"](id)
                    );
                  }
                  return true;
                });
                return col.panels.length;
              }).length
          );
        }
      }
      return null;
    },
    grid() {
      let self = this;
      let grid = [];
      if (this.busy) return grid;
      let layout = self.layout;
      if (layout) {
        layout.forEach((items) => {
          grid.push(self.buildClasses(self.row(items)));
        });
      }
      return grid.filter((row) => row.length > 0);
    },
    largePanel() {
      return this.expandedPanel || this.fullscreenPanel || "";
    },
    sidebar() {
      return this.$store.getters["dashboard/sidebar"] || null;
    }
  },
  watch: {
    expandedPanel(n) {
      if (!this.display.render_version) return;
      this.refresh();
      if (!n && this.layout) {
        this.delayedResize();
      } else if (n) {
        window.scrollTo(0, 0);
      }
    },
    sidebar: {
      handler() {
        if (!this.display.render_version) return;
        if (!this.expandedPanel && this.layout) {
          this.delayedResize();
        }
      },
      deep: true
    },
    dirty(n) {
      if (n) {
        this.dirty = false;
        this.$nextTick(() => {
          this.delayedResize();
        });
      }
    }
  },

  methods: {
    panelMounted(panelName) {
      let mountedPanels = this.mountedPanels || {};
      this.$set(this.mountedPanels, panelName, true);
      if (Object.keys(mountedPanels).length >= this.panels.length) {
        this.$nextTick(() => {
          setTimeout(() => (this.dirty = true), 10, this);
        });
      }
    },
    can(panel) {
      if (panel) {
        if (panel.rule) {
          return this.$can("view", panel.rule);
        }
        return true;
      }
      return false;
    },
    row(items) {
      let lst = JSON.parse(JSON.stringify(items));
      lst.forEach((item) => {
        item.panels = item.panels.filter((name) => {
          return this.panels.indexOf(name) >= 0;
        });
      });
      return lst;
    },
    buildClasses(row) {
      let max_col = 12;
      let columns = [];
      for (var icol in row) {
        let hasPanel = false;
        for (var ipanel in row[icol].panels) {
          var panelName = row[icol].panels[ipanel];
          if (panelName) {
            hasPanel = true;
          } else {
            if (icol > 0) {
              row[icol - 1].width += row[icol].width;
            }
          }
        }
        if (hasPanel) {
          columns.push(row[icol]);
        }
      }
      var sum_informed = columns
        .map((i) => {
          return i.width || 0;
        })
        .reduce((a, b) => a + b, 0);
      var sum_pending = 100 - sum_informed;
      var empty_ones = columns.filter((i) => {
        return !("width" in i) || i.width <= 0 || i.width >= 100;
      }).length;
      var portion = empty_ones ? sum_pending / empty_ones : 0;
      var sum_calc = 0;

      columns
        .map((i) => {
          i.width_calc = i.width || portion;
          sum_calc += i.width_calc;
          return i;
        })
        .map((i) => {
          i.width_class_value =
            sum_calc > 0
              ? Math.round((i.width_calc * max_col) / sum_calc)
              : max_col;
          i.column_class =
            "col-sm-" +
            (i.width_class_value > 0 ? i.width_class_value : max_col);
          return i;
        });
      return columns;
    },
    refresh() {
      this.busy = true;
      this.$nextTick(() => {
        this.busy = false;
      });
    },
    connectorPanel(panelName) {
      return (this?.display?.panels || []).find(({name}) => name == panelName);
    },
    panel(panelName) {
      return this.connectorPanel(panelName);
    },
    isRendered() {
      return !(this?.panels || []).some((name) => {
        return document.getElementsByClassName(`__${name}__`).length == 0;
      });
    },
    onResizeReferenceColumn(row) {
      if (!this.display.render_version || !this.layout) return;
      let panelName = this.layout[row][0].panels[0];
      let el = document.getElementsByClassName(`__${panelName}__`);
      return el.length
        ? el[0].parentNode.parentNode.parentNode.clientHeight
        : 0;
    },
    onResizeColumn(row, col, rowHeight) {
      // console.log(`onResizeColumn ${col}x${row} - ${this.isRendered()}`);
      let el = null;
      let panel = null;
      let total = 1;
      let nAutos = 0;
      let dist = [];
      (this.layout[row][col].panels || []).forEach((panelName) => {
        panel = this.panel(panelName);
        el = document.getElementsByClassName(`__${panelName}__`);
        // console.log(
        //   `onResizeColumn Height:${rowHeight} => ${col}x${row} ${
        //     !panel || !el || !el.length ? "??" : "ok"
        //   } `
        // );
        if (!panel || !el || !el.length) return;
        if ("heightProportion" in panel) {
          let perc = panel.heightProportion;
          if (perc > 0) {
            total -= panel.heightProportion;
          } else {
            perc = "?";
            nAutos += 1;
          }
          dist.push({name: panel.name, perc: perc});
          if (el && el.length) {
            el[0].style.height = "0";
            el[0].style["min-height"] = "auto";
            el[0].style["max-height"] = "auto";
          }
        }
      });

      rowHeight -=
        (this.layout[row][col].panels || []).length * panelBottomMargin;
      dist.forEach((item, i) => {
        if (item.perc == "?") {
          item.perc = total / nAutos;
        }
        var height = Math.round(rowHeight * item.perc);
        //        if (i > 0) height -= panelBottomMargin;
        panel = this.panel(item.name);
        el = document.getElementsByClassName(`__${item.name}__`);
        if (el && el.length) {
          this.setPanelHeight(panel, el[0], height);
        }
      });
    },
    setPanelHeight(panel, el, height) {
      let style = el.style;
      var minHeight = ((panel?.style || {})["min-height"] || "").replace(
        /(px|auto)/gi,
        ""
      );
      var maxHeight = ((panel?.style || {})["max-height"] || "").replace(
        /(px|auto)/gi,
        ""
      );
      // style.minHeight = minHeight ? `${minHeight}px` : "auto";
      // style.maxHeight = maxHeight ? `${maxHeight}px` : "auto";
      if (minHeight) {
        height = height > minHeight ? height : minHeight;
        style.minHeight = `${minHeight}px`;
      } else {
        style.minHeight = 0;
      }
      if (maxHeight) {
        height = height < maxHeight ? height : maxHeight;
        style.maxHeight = `${maxHeight}px`;
      } else {
        //style.maxHeight = `${height}px`;
      }
      style.height = `${height}px`;
      return parseInt(height);
    },
    delayedResize: debounceTime(10, function() {
      if (this.isRendered()) this.resize();
    })
  }
};
</script>
