var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"modalDialog",staticClass:"modal fade",staticStyle:{"clear":"both"},attrs:{"role":"dialog"}},[_c('div',{staticClass:"modal-dialog modal-lg",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_vm._m(0),_c('div',{staticClass:"modal-title text-left"},[_vm._v(" "+_vm._s(_vm.$tc("data_replacement", 2))+" "),_c('span',{staticClass:"badge"},[_vm._v(" "+_vm._s((_vm.$refs.stbl && _vm.$refs.stbl.nItems) || 0)+" ")])])]),_c('div',{staticClass:"modal-body"},[_c('section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12"},[_c('div',{staticClass:"form-group form-group-sm",staticStyle:{"margin":"0 0 15px 0"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("contract"))+" ("+_vm._s(_vm.$t("source"))+") "),(_vm.dataSourceListNotFound)?_c('i',{staticClass:"fa fa-exclamation-triangle text-danger",attrs:{"title":_vm.$t('synoptic.data_not_found')}}):_vm._e(),(!_vm.dataSourceListNotFound)?_c('i',{staticClass:"fa fa-check text-success",attrs:{"title":_vm.$t('alert_icons.success')}}):_vm._e()]),_c('Tooltip',{attrs:{"title":_vm.$t('hints.original_screen_contract')}}),_c('v-select',{staticClass:"contract-selector",attrs:{"disabled":_vm.loadingData,"options":_vm.contractList,"reduce":({id}) => id,"label":"name"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (() => {
                          false;
                        }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(item){return [_c('div',{staticStyle:{"display":"flex","align-items":"baseline"}},[_c('span',[_vm._v(" "+_vm._s(item.name || _vm.$t("contract"))+" "),(item.id != '0')?_c('span',[_vm._v("#"+_vm._s(item.id)+" ")]):_vm._e()])])]}},{key:"option",fn:function(option){return [_c('span',{staticClass:"contract-selector-option"},[_vm._v(" "+_vm._s(option.name || _vm.$t("contract"))+" "),(option.id != '0')?_c('span',[_vm._v("#"+_vm._s(option.id)+" ")]):_vm._e()])]}},{key:"no-options",fn:function(){return [_c('span',{staticStyle:{"font-size":"90%"}},[_vm._v(" "+_vm._s(_vm.$t("no_result_found"))+" ")])]},proxy:true}]),model:{value:(_vm.contractId),callback:function ($$v) {_vm.contractId=$$v},expression:"contractId"}})],1)])])]),_c('div',{staticClass:"col-md-6"})]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group form-group-sm"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("data_list")))]),_c('Tooltip',{attrs:{"title":_vm.$t('titles.filter_by_data')}}),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.query),expression:"query"}],ref:"query",staticClass:"form-control",staticStyle:{"height":"32px","z-index":"0"},attrs:{"type":"text","placeholder":_vm.$t('placeholders.search')},domProps:{"value":(_vm.query)},on:{"input":function($event){if($event.target.composing)return;_vm.query=$event.target.value}}}),_c('div',{staticClass:"input-group-addon btn",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.query = '';
                      _vm.$refs.query.focus();}}},[_c('i',{class:_vm.query ? 'fa fa-close' : 'fa fa-search',style:({
                        opacity: _vm.query ? '0.9' : '0.6'
                      })})])])],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("destination_connector")))]),_c('Tooltip',{attrs:{"title":_vm.$t('hints.destination_connector')}}),_c('v-select',{staticClass:"destination",attrs:{"options":_vm.connectorList,"reduce":(option) => option.id,"label":"name"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)])]),(_vm.loadingData)?_c('div',{staticClass:"overlay"},[_c('i',{staticClass:"fa fa-refresh fa-spin"})]):_vm._e(),(_vm.ready && _vm.items.length)?_c('SearchableTable',{key:_vm.cid,ref:"stbl",style:({opacity: _vm.loadingData ? '.5' : '1'}),attrs:{"searchEnabled":false,"items":_vm.items,"fields":_vm.fields},scopedSlots:_vm._u([{key:"data_from",fn:function(entry){return [_c('div',{staticClass:"form-group form-group-sm",staticStyle:{"margin-bottom":"0"}},[_c('div',{staticClass:"form-control disabled",attrs:{"title":_vm.itemTitle(entry.item.from)}},[_c('span',[_vm._v(" "+_vm._s(entry.item.from.id)+" - ")]),(_vm.loadingData)?_c('span',[_c('i',{staticClass:"fa fa-refresh fa-spin"}),_vm._v(" "+_vm._s(_vm.$t("loading"))+" ")]):_c('span',[_vm._v(" "+_vm._s((entry.item.from && _vm.$t(entry.item.from.name)) || `${_vm.$t("not_found")}`)+" ")])])])]}},{key:"data_to",fn:function(entry){return [(_vm.selected && !_vm.loadingData)?_c('ControlDataSelector',{staticStyle:{"margin-bottom":"0"},attrs:{"connectorSelector":false,"value":(entry &&
                    entry.item &&
                    entry.item.to &&
                    entry.item.to.id) ||
                    '',"connectorId":_vm.selected,"allowedTypes":['bool', 'float', 'int', 'string']},on:{"input":function($event){return _vm.setDataTo(entry.item, $event)}}}):_c('div',{staticClass:"form-group form-group-sm",staticStyle:{"margin-bottom":"0"}},[_c('div',{staticClass:"form-control disabled",attrs:{"title":`${_vm.$t('invalid_value')}\n${_vm.$t('select_a_connector')}`}})])]}}],null,false,1470265336)}):_vm._e()],1)]),_c('div',{staticClass:"modal-footer",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"pull-left"},[_c('button',{staticClass:"btn btn-default",attrs:{"data-dismiss":"modal","aria-label":"Close"}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])]),_c('div',{staticClass:"pull-right"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":!_vm.canSave},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-exclamation-triangle"}),_vm._v(" "+_vm._s(_vm.$t("confirm"))+" ")])]),(_vm.canSave)?_c('div',{staticClass:"text-danger warning"},[_c('i',{staticClass:"fa fa-exclamation-triangle",staticStyle:{"margin-right":"5px"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('hints.data_replacement'))}})]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
}]

export { render, staticRenderFns }