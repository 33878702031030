<template>
  <div class="me" v-if="screen">
    <TogglePanel
      :title="$t('screen_properties')"
      :icon="{
        before: 'fa fa-sliders',
        collapse: 'fa-minus',
        expand: 'fa-plus'
      }"
      persistent="toggle_screen_properties"
      :key="cid"
    >
      <TogglePanel title="general" persistent="toggle_screen_general">
        <div class="form-group form-group-sm">
          <label for="">{{ $t("screen_name") }}</label>
          <input
            class="form-control"
            type="text"
            v-model="title"
            :style="{color: isNew ? '#a94442' : 'inherit'}"
            :title="isNew ? `${$t('new_screen')} [${screen.id}]` : title"
          />
        </div>
        <ReferenceConnectorSelector
          :screenId="screenId"
          :inline="false"
          v-if="screenId"
        />

        <DashboardSwitcher v-if="connectorId" :connectorId="connectorId" />
        <ScreenProcessArea
          :screenId="screenId"
          :processArea="screen.process_area"
          :disabled="screen.public"
          :inline="false"
          style="margin-bottom: 15px"
        />
        <TagsForm v-model="tags" />
        <PoolingIntervalSelector
          v-if="isPoolingEnabled"
          title="pooling_interval"
          v-model="refreshInterval"
          :list="poolingIntervalList"
        />
      </TogglePanel>
      <TogglePanel
        title="titles.visible_components"
        persistent="toggle_screen_components"
      >
        <div>
          <div class="visibility-control">
            <label
              class="clicable"
              @click.stop.prevent="pageHeader = !pageHeader"
            >
              <i :class="pageHeader ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
              {{ $t("titles.header") }}
              <Tooltip :title="$t('hints.header')" />
            </label>
          </div>
          <div class="visibility-control">
            <label
              class="clicable"
              @click.stop.prevent="pageSideBar = !pageSideBar"
            >
              <i :class="pageSideBar ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
              {{ $t("titles.sidebar") }}
              <Tooltip :title="$t('hints.sidebar')" />
            </label>
          </div>
        </div>
      </TogglePanel>
      <TogglePanel title="maintenance" persistent="toggle_screen_maintenance">
        <div class="vertical-commands">
          <div :class="{disabled: dataMapper}">
            <div
              class="btn btn-xs btn-default"
              @click="dataMapper = !dataMapper"
            >
              <i class="fa fa-random"></i>
              {{ $tc("data_replacement", 2) }}
            </div>
          </div>
          <div :class="{disabled: downloading}">
            <DashboardDownloadBtn
              class="btn btn-xs btn-default"
              :screenId="screenId"
              :disabled="downloading"
              style="color: inherit; display: block"
            >
              <template>
                <i
                  :class="
                    downloading ? 'fa fa-refresh fa-spin' : 'fa fa-download'
                  "
                />
                {{ $t("download_this_screen") }}
              </template>
            </DashboardDownloadBtn>
          </div>
          <div :class="{disabled: downloading}" v-if="!removing && screen.path">
            <div
              class="btn btn-xs btn-default"
              @click.stop.prevent="
                $utils.navigate(`${screen.path}?_=${new Date().getTime()}`, {
                  new_tab: true
                })
              "
            >
              <i class="fa fa-external-link" />
              {{ $t("synoptic.events.actions.screen_tab") }}
            </div>
          </div>
          <div :class="{disabled: removing}">
            <div
              class="btn btn-xs btn-default text-danger"
              @click.stop.prevent="remove"
              :disabled="removing"
            >
              <i :class="removing ? 'fa fa-refresh fa-spin' : 'fa fa-trash'" />
              {{ $t("delete_this_screen") }}
            </div>
          </div>
        </div>
      </TogglePanel>
    </TogglePanel>
    <portal to="modal" v-if="dataMapper">
      <DashboardDataMapper v-if="dataMapper" @close="dataMapper = false" />
    </portal>
  </div>
</template>

<script>
import ScreenService from "@/services/screen.js";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ReferenceConnectorSelector from "@/components/editor/reference-connector-selector";
import ScreenProcessArea from "@/components/editor/screen-process-area";
import TagsForm from "@/components/registration/tags-form.vue";
import PoolingIntervalSelector from "@/components/pooling-interval-selector.vue";
import DashboardSwitcher from "@/components/control-sidebar/property-editors/dashboard-switcher.vue";
import DashboardDownloadBtn from "@/components/editor/dashboard-download-btn.vue";
import Tooltip from "@/components/tooltip.vue";
import DashboardDataMapper from "@/components/editor/dashboard-data-mapper.vue";
export default {
  name: "DashboardForm",
  components: {
    TogglePanel,
    ReferenceConnectorSelector,
    ScreenProcessArea,
    TagsForm,
    PoolingIntervalSelector,
    DashboardSwitcher,
    DashboardDownloadBtn,
    Tooltip,
    DashboardDataMapper
  },
  data: () => ({
    removing: false,
    downloading: false,
    dataMapper: false
  }),
  computed: {
    contract() {
      let user = this.$store.getters["user/loggedUser"];
      return (user && user.contract) || null;
    },
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    screenId() {
      return this?.draft?.screenId ? parseInt(this?.draft?.screenId) : "";
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || [];
    },
    screen() {
      return this.screenId && this?.screens?.length
        ? this.screens.find(({id}) => id == this.screenId)
        : null;
    },
    template() {
      return this?.draft?.template;
    },
    pageHeader: {
      set(value) {
        let template = JSON.parse(JSON.stringify(this.template));
        template.page_header = template.page_header || {};
        template.page_header.show = value;
        this.saveDraft(template);
      },
      get() {
        return this?.template?.page_header?.show || false;
      }
    },
    pageSideBar: {
      set(value) {
        let template = JSON.parse(JSON.stringify(this.template));
        template.page_side_bar = template.page_side_bar || {};
        template.page_side_bar.show = value;
        this.saveDraft(template);
      },
      get() {
        return this?.template?.page_side_bar?.show || false;
      }
    },
    tags: {
      set(value) {
        let template = JSON.parse(JSON.stringify(this.template));
        template.draft = template.draft || {};
        template.draft.tags = template.draft.tags || {};
        template.draft.tags = value;
        this.saveDraft(template);
      },
      get() {
        return this?.template?.draft?.tags || [];
      }
    },
    refreshInterval: {
      set(value) {
        let template = JSON.parse(JSON.stringify(this.template));
        template.refreshInterval = value;
        this.saveDraft(template);
      },
      get() {
        // !IMPORTANT NOTE!
        // The legacy value of this property was stored as 5m (300000), which were
        // are now a too high standard. Therefore, 5min will be set to 300001ms and legacy
        // value will be replaced by the default one (30s)
        return this.template.refreshInterval == 300000
          ? this?.$root?.config?.pooling_interval?.value || 30000
          : this.template.refreshInterval;
      }
    },
    title: {
      set(value) {
        let template = JSON.parse(JSON.stringify(this.template));
        template.title = value;
        this.saveDraft(template);
      },
      get() {
        let value =
          this?.template?.title == "Untitled screen"
            ? this?.screen?.name
            : this?.template?.title;
        return value; //this.$t(value);
      }
    },
    poolingIntervalList() {
      return [
        {
          value: 0,
          label: this.$tc("disabled", 1)
        },
        {
          value: 30000,
          label: "30 s"
        },
        {
          value: 60000,
          label: "1 min"
        },
        {
          value: 120000,
          label: "2 min"
        },
        {
          value: 150000,
          label: "2.5 min"
        },
        {
          value: 180000,
          label: "3 min"
        },
        {
          value: 240000,
          label: "4 min"
        },
        {
          value: 300001,
          label: "5 min"
        },
        {
          value: 600000,
          label: "10 min"
        },
        {
          value: 900000,
          label: "15 min"
        }
      ];
    },
    isNew() {
      return parseInt(this.screenId) < 0;
    },
    connectorId() {
      return this?.refMap?.conn1 || "";
    },
    refMap() {
      return this.$store.getters["dashboard/screenRefMap"](this.screenId);
    },
    cid() {
      return this?.screen?.etag || this?.screen?.id || 1;
    },
    isPoolingEnabled() {
      if (this?.$root?.config?.mqtt?.modbus_enabled) return false;
      const connector = this.connectorId
        ? (this.$store.getters["dashboard/connectorList"] || []).find(
            ({id}) => parseInt(id) == parseInt(this.connectorId)
          )
        : null;
      return connector && connector?.protocol?.is_mqtt_protocol ? false : true;
    }
  },
  methods: {
    saveDraft(template) {
      if (this.removing) return;
      this.$store.dispatch("dashboard/saveDraft", {
        screenId: this.screenId,
        template: template
      });
    },
    remove() {
      let screen = JSON.parse(JSON.stringify(this.screen));
      if (screen.public || this.removing) return;
      // let screen = {
      //   id: this.screen.id,
      //   name: this?.template?.title || this?.screen?.name || ""
      // };
      // ---
      let config = {
        item: screen,
        type: "screen",
        rule: "DadoEscrita" // TODO: replace it by the proper police
      };
      this.$utils.confirmRemoval(this, config).then((confirmed) => {
        if (!confirmed) return;
        let contract_portal_data_payload = null;
        if (this?.contract?.portal_data?.search_page) {
          let search_page = {};
          let check = [0, 0];
          for (var p in this.contract.portal_data.search_page) {
            check[0] += 1;
            if (
              this.contract.portal_data.search_page[p].screen_id != screen.id
            ) {
              check[1] += 1;
              search_page[p] = JSON.parse(
                JSON.stringify(this.contract.portal_data.search_page[p])
              );
            }
          }
          if (check[0] != check[1]) {
            contract_portal_data_payload = this.contract.portal_data || {};
            contract_portal_data_payload.search_page = search_page;
          }
        }
        // not published, just discharge it
        if (parseInt(screen.id) <= 0) {
          this.$store.dispatch("dashboard/remove", screen.id).then(() => {
            this.$router.push("/dashboard/edit/screen");
          });
          return;
        }
        this.removing = true;
        let srv = new ScreenService();
        srv.remove(screen).then((result) => {
          this.removing = false;
          if (result) {
            if (contract_portal_data_payload) {
              this.updateContractPortalData(contract_portal_data_payload);
            }
            this.$store.dispatch("dashboard/remove", screen.id).then(() => {
              this.$router.push("/dashboard/edit/screen");
            });
          }
        });
      });
    },
    download() {}
  },
  created() {
    if (this?.template) {
      let value =
        this?.template?.title == "Untitled screen"
          ? this?.screen?.name
          : this?.template?.title;
      this.title = this.$t(value || "screen_name");
    }
  }
};
</script>

<style scoped>
.me {
  margin-bottom: 10px;
  /* padding-inline: 15px; */
  position: relative;
  background: white;
}

.me .toggle-panel::v-deep .box-header {
  margin-inline: -15px;
}

.visibility-control {
  padding: 4px 2px;
  font-size: 12pt;
  color: #666;
}

.visibility-control > label > i {
  padding-right: 5px;
}

.vertical-commands {
  margin: 0px;
  /* margin: 0 -15px; */
}

.vertical-commands > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border-radius: 5px;
}

.vertical-commands > div.disabled {
  opacity: 0.8;
}

.vertical-commands > div > div,
.vertical-commands > div > span {
  font-size: 12pt;
  width: 100%;
  text-align: left;
}

.vertical-commands > div > div.btn-default.text-danger {
  color: #a94442;
}

.vertical-commands i {
  min-width: 14px;
  margin-left: 10px;
  margin-right: 8px;
}
</style>
