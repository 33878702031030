<template>
  <header class="main-header">
    <router-link :to="website" class="logo" :target="websiteTarget">
      <span class="logo-mini">
        <img v-bind:src="img_logo" />
      </span>
      <span class="logo-lg">
        <img v-bind:src="img_logo" />
        <span v-html="title"></span>
      </span>
    </router-link>
    <nav class="navbar navbar-static-top">
      <a
        href="#"
        class="sidebar-toggle"
        data-toggle="push-menu"
        role="button"
        v-if="!isPublicAccess && !$attrs.nosidebar"
      >
        <span class="sr-only">Toggle navigation</span>
      </a>
      <span class="logo-mobile">
        <img v-bind:src="img_logo" />
        <span v-html="title"></span>
      </span>
      <portal-target name="context-navbar" multiple> </portal-target>
      <div class="navbar-custom-menu">
        <ul class="nav navbar-nav" v-if="customOptions && customOptions.length">
          <li v-for="(item, ix) in customOptions" :key="ix">
            <a
              v-if="item.target && item.label"
              :href="item.target"
              :title="item.description || item.label"
              :target="item.external ? '_blank' : '_self'"
              :style="item.style || {}"
            >
              <i :class="item.icon" v-if="item.icon"></i>
              {{ $t(item.label) }}
            </a>
          </li>
        </ul>
        <ul class="nav navbar-nav" v-if="isOwner">
          <TermsOfServiceButton />
        </ul>
        <ul class="nav navbar-nav" v-if="!isPublicAccess">
          <NotificationsMenu />
        </ul>
        <ul class="nav navbar-nav" v-if="!isPublicAccess">
          <!-- begin refresh button -->
          <li v-if="isManualRefreshAllowed">
            <a
              href="javascript:void(0)"
              ref="refreshBtn"
              :class="(!manualRefreshEnabled && 'disabled') || ''"
              @click.stop.prevent="onRefresh"
              style="z-index: 3"
            >
              <Tooltip
                @click="$refs.refreshBtn.click()"
                :title="manualRefreshTooltip"
                :icon="busy ? 'fa fa-refresh fa-spin' : 'fa fa-refresh'"
              />
            </a>
          </li>
          <!-- end refresh button -->

          <!-- begin profile info -->
          <ProfileDropDownMenu ref="profile"></ProfileDropDownMenu>
          <!-- end profile info -->

          <!-- user settings -->
          <li class="hidden-sm hidden-xs">
            <a
              href="#"
              data-toggle="control-sidebar"
              @click.stop.prevent="onSettingsClick"
              data-testid="toggle-settings"
            >
              <i class="fa fa-gear"></i>
            </a>
          </li>
          <!-- user settings-->
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import ProfileDropDownMenu from "@/components/profile-dropdown-menu";
import NotificationsMenu from "@/components/header/notifications-menu";
import TermsOfServiceButton from "./header/terms-of-service-button.vue";
import UserPreferencesSidebarPanel from "@/components/control-sidebar/property-editors/user-preferences-sidebar-panel.vue";
import Tooltip from "@/components/tooltip.vue";
export default {
  name: "PageHeader",
  components: {
    ProfileDropDownMenu,
    NotificationsMenu,
    TermsOfServiceButton,
    Tooltip
  },
  props: {
    page_header: {
      type: Object,
      required: false,
      default: () => ({
        show: true,
        custom_links: []
      })
    }
  },
  data() {
    return {
      showSettings: false,
      showRefreshButton: false,
      remainingTime: 0,
      busy: false
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"];
    },
    manualRefreshTimeout() {
      return this?.contract?.portal_data?.manualRefreshTimeout || 10000;
    },
    manualRefreshEnabled: {
      set(value) {
        return this.$store.dispatch("dashboard/setManualRefresh", value);
      },
      get() {
        return (
          this.$store.getters["dashboard/manualRefresh"] && !this.remainingTime
        );
      }
    },
    isManualRefreshAllowed() {
      return (
        this.showRefreshButton &&
        /\/dashboard\/(log|equipment|search).*/.test(this.$route.path)
      );
    },
    remainingTimeInSeconds() {
      return this.$utils.sprintf(" %ds", this.remainingTime);
    },
    manualRefreshTooltip() {
      return this.$t(
        this.manualRefreshEnabled ? "update_page" : "please_wait",
        {
          timeout: this.remainingTimeInSeconds
        }
      );
    },
    customOptions() {
      return this?.page_header?.options || [];
    },
    isReady: function() {
      return this.$store.getters["dashboard/isReady"];
    },
    user() {
      return this.$store.getters["user/loggedUser"] || null;
    },
    isPublicAccess() {
      return !this.user || this.user.is_public_access;
    },
    isOwner() {
      return this.user && this.user.is_root;
    },
    userEmail: function() {
      return this?.user?.email || "";
    },
    userFullName: function() {
      if (!this.user) return "";
      return `${this.user.first_name} ${this.user.last_name}`;
    },
    userPicture: function() {
      return this?.user?.picture || "/static/common/images/nopicture.gif";
    },
    img_banner: function() {
      return (
        this.$store.getters["dashboard/property"]("company_banner") ||
        "/static/common/images/hitecnologia_logo.png"
      );
    },
    img_logo: function() {
      return (
        this.$store.getters["dashboard/property"]("company_logo") ||
        "/static/common/images/hitecnologia_mascot_70.png"
      );
    },
    title: function() {
      return (
        this.$store.getters["dashboard/property"]("company_name") ||
        "<b>HI</b> Tecnologia"
      );
    },
    website: function() {
      return (
        this.$store.getters["dashboard/property"]("company_website") ||
        "https://hitecnologia.com.br"
      );
    },
    websiteTarget() {
      return /^(http|https).*/.test(this.website) ? "_blank" : "_self";
    }
  },
  methods: {
    logout: function() {
      this.$store.dispatch("user/logout");
      this.$store.dispatch("reset");
      this.$router.push("/");
    },
    resetManualRefreshTimer() {
      clearTimeout(this._waitingTimer);
      this._waitingTimer = null;
      this.remainingTime = 0;
      this.manualRefreshEnabled = true;
    },
    onRefresh() {
      if (!this.manualRefreshEnabled) return;
      this.busy = true;
      this.manualRefreshEnabled = false; // centralized store
      this.remainingTime = this.manualRefreshTimeout / 1000;
      this._waitingTimer = setInterval(
        () => {
          this.remainingTime -= 1;
          if (this.remainingTime <= 0) {
            this.resetManualRefreshTimer();
          }
        },
        1000,
        this
      );
      this.$root.$emit("refreshPage");
    },
    refreshDone() {
      this.busy = false;
    },
    onSettingsClick() {
      this.$root.$emit(
        "controlSidebar:setContent",
        UserPreferencesSidebarPanel,
        {
          toggle: true,
          showToggle: false,
          clickAway: {
            allowed: ["#create-dashboard-tab-modal", ".settings-form"]
          }
        }
      );
      this.$nextTick(() => {
        if (this?.$refs?.profile?.$el?.classList?.contains("open")) {
          this.$refs.profile.$el.click();
        }
      });
    }
  },
  mounted() {
    setTimeout(() => {
      this.showRefreshButton = true;
      this.manualRefreshEnabled = true;
    }, 2000);
  },
  created() {
    this.$root.$on("refreshDone", this.refreshDone);
  },
  beforeDestroy() {
    this.resetManualRefreshTimer();
    this.$root.$off("refreshDone", this.refreshDone);
  }
};
</script>

<style scoped>
.logo-mobile {
  display: none;
}

.logo-mobile > img {
  width: 40px;
  height: 40px;
}

.logo-mobile > span {
  font-size: 18pt;
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header > img {
  height: 100px !important;
  width: 100px !important;
}

.user-image-right {
  float: right !important;
  margin-left: 10px !important;
  margin-right: 0 !important;
}

.skin-black-light .main-header li.user-header {
  background-color: #dbe0ea !important;
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p {
  color: #000;
}
.navbar-nav > li > a:hover {
  opacity: 0.8;
}
.navbar-nav > li > a:focus {
  background: inherit !important;
  color: inherit !important;
}

.navbar-nav > li > a.disabled,
.navbar-nav > li > a.disabled:hover {
  position: relative;
  color: #999 !important;
  cursor: not-allowed;
}

.navbar-nav > li > a.disabled > span.remaining-time {
  color: red;
  font-weight: 600;
  position: absolute;
  margin-left: -13px;
}

@media (max-width: 767px) {
  header.main-header > a {
    display: none;
  }
  .logo-mobile {
    float: left;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media (max-width: 991px) {
  .navbar-custom-menu > .navbar-nav {
    float: left;
  }
}
</style>
