<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" ref="dlgImport">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">{{ $t("restore_previous_versions") }}</h4>
          </div>
          <div class="modal-body">
            <div>
              <div class="screen-name">{{ screen.name }}</div>
            </div>
            <div class="box box-default">
              <!-- /.box-header -->
              <div class="box-body text-left">
                <div class="row" v-if="items && items.length">
                  <div class="col-sm-5">
                    <DatetimeRangePicker
                      v-if="this.interval.original"
                      :startRef="this.interval.original.startDate"
                      :endRef="this.interval.original.endDate"
                      :restricted="true"
                      @interval-changed="onIntervalChanged($event)"
                      style="padding: 10px 0 !important"
                    />
                  </div>
                  <div class="col-sm-7">
                    <form
                      action="#"
                      method="get"
                      class="sidebar-form"
                      style="height: 34px"
                    >
                      <div class="input-group">
                        <input
                          type="text"
                          class="form-control"
                          :placeholder="$t('search')"
                          v-model="query"
                          style="height: 32px"
                          ref="query"
                        />
                        <span class="input-group-btn">
                          <span
                            class="btn btn-flat"
                            style="height: 32px"
                            @click="query ? resetSearch() : () => {}"
                          >
                            <i class="fa fa-close" v-if="this.query"></i>
                            <i class="fa fa-search" v-else></i>
                          </span>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
                <template v-if="items">
                  <div style="position: relative">
                    <div class="overlay overlay-local" v-if="busy">
                      <i class="fa fa-refresh fa-spin"></i>
                    </div>
                    <div class="result-header">
                      <span
                        class="clicable"
                        @click.stop.prevent="resetSearch"
                        :title="$t('reset_search')"
                      >
                        <span style="padding: 0 6px 0 4px">
                          <i class="fa fa-history"></i>
                        </span>
                        <span v-if="items.length != list.length">
                          {{ list.length }}/</span
                        ><span>{{ items.length }}</span>
                        {{ $t("versions") }}
                      </span>
                    </div>
                    <SearchableTable
                      v-if="items"
                      class="result-table"
                      ref="tbl"
                      :key="cid"
                      :searchEnabled="false"
                      :showSelected="true"
                      :items="list"
                      :fields="fields"
                      :clientSort="true"
                      :visibleItems="true"
                      @visibleItems="fetchVersionsMetaData($event)"
                      @select="onSelect($event)"
                      @unselect="onSelect()"
                    >
                      <!-- begin column date time -->
                      <template #fmtDateTime="entry">
                        <span>
                          <span>
                            {{ entry.item.fmtDateTime }}
                          </span>
                          <a :href="entry.item.url" target="_blank">
                            <i
                              :title="$t('synoptic.events.actions.page_tab')"
                              class="fa fa-external-link fa-hover"
                              @click.stop.prevent="
                                $utils.navigate(entry.item.url, {
                                  new_tab: true
                                })
                              "
                            ></i>
                          </a>
                        </span>
                      </template>
                      <!-- end column date time -->

                      <!-- begin column version -->
                      <template #versionId="entry">
                        <span>
                          <span>
                            <i
                              class="fa fa-check"
                              :style="{
                                color:
                                  latestVersion == entry.item.versionId
                                    ? ''
                                    : 'transparent'
                              }"
                            ></i>
                            <span
                              :title="
                                latestVersion == entry.item.versionId
                                  ? $t('latest_version')
                                  : $utils.sprintf(
                                      $t('version_older_than_the_latest'),
                                      items.length - entry.item.seq
                                    )
                              "
                            >
                              <span
                                v-if="
                                  entry.item.status == 'ready' &&
                                  entry.item.revision_code
                                "
                                :title="$t('user_identification')"
                                style="margin-left: 10px"
                              >
                                {{ entry.item.revision_code }}
                              </span>
                              <span
                                v-else-if="entry.item.status == 'loading'"
                                style="margin-left: 18px; color: #999"
                              >
                                <i class="fa fa-refresh fa-spin"></i>
                              </span>
                              <span v-else style="margin-left: 18px"> - </span>
                            </span>
                          </span>
                          <a :href="entry.item.url" target="_blank">
                            <i
                              :title="$t('synoptic.events.actions.page_tab')"
                              class="fa fa-external-link fa-hover"
                              @click.stop.prevent="
                                $utils.navigate(entry.item.url, {
                                  new_tab: true
                                })
                              "
                            ></i>
                          </a>
                        </span>
                      </template>
                      <!-- end column version -->

                      <!-- begin column version remarks -->
                      <template #revision_comment="entry">
                        <div class="revision-column">
                          <template
                            v-if="
                              entry.item.status == 'ready' &&
                              entry.item.revision_comment
                            "
                          >
                            <div class="revision-column-content">
                              <span
                                :class="
                                  expanded[entry.item.versionId]
                                    ? 'text-bold'
                                    : ''
                                "
                              >
                                {{ entry.item.revision_comment }}
                              </span>
                            </div>
                            <div
                              :title="
                                expanded[entry.item.versionId]
                                  ? $t('close')
                                  : $t('open')
                              "
                              class="revision-column-expand"
                              @click.stop.prevent="
                                expanded[entry.item.versionId] =
                                  !expanded[entry.item.versionId]
                              "
                            >
                              <i
                                class="fa fa-chevron-up"
                                v-if="expanded[entry.item.versionId]"
                              ></i>
                              <i class="fa fa-chevron-down" v-else></i>
                            </div>
                          </template>
                          <template v-else-if="entry.item.status == 'loading'">
                            <div class="revision-column-content">
                              <span style="color: #999">
                                <i class="fa fa-refresh fa-spin"></i>
                                <span style="margin-left: 10px">
                                  {{ $t("loading") }}...
                                </span>
                              </span>
                            </div>
                          </template>
                          <template v-else>
                            <div class="revision-column-content">
                              <span> - </span>
                            </div>
                          </template>
                        </div>
                      </template>
                      <!-- end column version remarks -->

                      <!-- begin row editable version remarks -->
                      <template #after_row="entry">
                        <tr
                          class="expanded-row"
                          v-if="expanded[entry.item.versionId]"
                        >
                          <td colspan="4">
                            <div>
                              <span
                                :title="$t('close')"
                                class="btn btn-xs close-btn"
                                @click.stop.prevent="
                                  expanded[entry.item.versionId] = false
                                "
                              >
                                <i class="fa fa-close"></i>
                              </span>
                              <div class="title">
                                {{ $t("release_notes") }}
                                <span
                                  class="btn btn-xs"
                                  v-if="entry.item.versionId != latestVersion"
                                  @click.stop.prevent="select(entry.item)"
                                >
                                  <i class="fa fa-pencil"></i>
                                </span>
                              </div>
                              <div class="body">
                                <textarea
                                  :id="entry.item.versionId"
                                  v-model="entry.item.revision_comment_changed"
                                  :disabled="
                                    !selected ||
                                    entry.item.versionId !=
                                      selected.versionId ||
                                    latestVersion == entry.item.versionId
                                  "
                                >
                                </textarea>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </template>
                      <!-- end row editable version remarks -->
                    </SearchableTable>
                  </div>
                </template>
                <div v-else-if="error" style="margin: 0 -10px">
                  <dl class="dl-horizontal">
                    <dt>{{ $t("versions") }}</dt>
                    <dd class="disabled-info-box text-danger">
                      {{ error }}
                    </dd>
                  </dl>
                </div>
                <div v-else class="text-center icon-loading" style="">
                  <i class="fa fa-refresh fa-spin"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" v-if="items && items.length">
            <template v-if="isScreen">
              <div class="pull-left" style="margin: 0 30px 20px 30px">
                <label
                  class="checkbox"
                  :class="template ? 'clicable' : 'disabled'"
                  :style="{color: template ? '#333' : '#999', 'z-index': 1}"
                >
                  <input
                    type="checkbox"
                    :disabled="!template"
                    v-model="navigate"
                  />
                  {{ $t("on_success_navigate_to") }}
                </label>
              </div>
              <div style="clear: both">
                <button
                  type="button"
                  class="btn btn-primary pull-right"
                  :disabled="!template"
                  @click.stop.prevent="onNewClicked"
                >
                  <i class="fa fa-plus-square"></i>
                  {{ $tc("new_draft", 1) }}
                </button>
                <span class="pull-left">
                  <button
                    type="button"
                    class="btn btn-default"
                    :disabled="!template"
                    @click.stop.prevent="onDraftClicked"
                  >
                    <i class="fa fa-undo"></i>
                    {{ $t("restore") }}
                  </button>
                  <div
                    :disabled="!template || latestVersion == selected.versionId"
                    style="display: inline-block; margin: 0 5px 0 40px"
                  >
                    <i class="fa fa-cloud-upload"></i>
                    {{ $t("publish_new_version") }}:
                  </div>
                  <button
                    type="button"
                    class="btn btn-default footer-input"
                    :disabled="!template || latestVersion == selected.versionId"
                    style=""
                    @click.stop.prevent="onPublishClicked"
                  >
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('version')"
                      v-model="new_version"
                      :disabled="
                        !template || latestVersion == selected.versionId
                      "
                      @click.stop.prevent
                    />
                    <i class="fa fa-check"></i>
                  </button>
                </span>
              </div>
            </template>
            <template v-else>
              <div class="row">
                <div class="col-xs-12 text-left">
                  <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="!template"
                    @click.stop.prevent="onRestoreScriptClicked"
                  >
                    <i class="fa fa-undo"></i>
                    {{ $t("restore") }}
                  </button>
                </div>
              </div>
            </template>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </div>
</template>

<script>
import {getUnpublishedList} from "@/services/dashboard.js";
import {warnUnsavedChanged} from "@/project/mixin-alert.js";
import SearchableTable from "@/components/searchable-table.vue";
import ScreenService from "@/services/screen.js";
import DatetimeRangePicker from "@/components/datetime-range-picker.vue";
// import ToolTip from "@/components/tooltip.vue";
const parseVersion = (item, path) => ({
  seq: item.seq,
  versionId: item.VersionId, // backend interface changes
  status: item.status,
  revision_code: item.revision_code,
  revision_comment: item.revision_comment,
  revision_comment_changed:
    item.revision_comment_changed || item.revision_comment,
  dateTime: item.date,
  ms: new Date(item.date).getTime(),
  url: `${path || ""}?versionId=${item.versionId}`,
  fmtDateTime: moment(item.date).format("L LTS")
});

export default {
  name: "RestoreScreenDialog",
  props: {
    screenId: {
      type: [Number, String],
      required: true,
      default: () => 0
    },
    show: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    SearchableTable,
    DatetimeRangePicker
    // ToolTip
  },
  data: () => ({
    cid: 0,
    selected: null,
    template: null,
    navigate: true,
    busy: false,
    items: null,
    error: "",
    query: "",
    interval: {
      original: null,
      user: null
    },
    expanded: {},
    new_version: ""
  }),
  computed: {
    list() {
      let lst = this?.items?.length
        ? this.items.map((i) => parseVersion(i, this.screen.path))
        : [];

      let self = this;
      lst = lst.filter((item) => {
        return (
          (!this?.interval?.user?.startDate ||
            item.ms >= this.interval.user.startDate) &&
          (!this?.interval?.user?.endDate ||
            item.ms <= this.interval.user.endDate) &&
          (!this.query.length ||
            self.$utils.queryStrAtr(self.query, {
              fmtDateTime: item.fmtDateTime,
              revision_code: item.revision_code,
              revision_comment: item.revision_comment,
              revision_comment_changed: item.revision_comment_changed
            }))
        );
      });
      return lst;
    },
    fields() {
      return [
        {
          name: "seq",
          title: " ",
          style: {"text-align": "center", width: "32px"}
        },
        {
          name: "fmtDateTime",
          title: this.$utils.capitalizeFirstLetter(this.$t("date_time")),
          style: {"white-space": "nowrap", width: "150px"}
        },
        {
          name: "versionId",
          title: this.$t("version"),
          parser(item) {
            return item.seq;
          },
          style: {"white-space": "nowrap", width: "100px"}
        },
        {
          name: "revision_comment",
          title: this.$t("release_notes"),
          style: {height: "32px", position: "relative", padding: 0}
        }
      ];
    },
    updatedAt() {
      let ts = this?.screen?.updated_at || "";
      return ts ? this.$dt.format(ts) : "";
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || [];
    },
    screen() {
      return (this.$store.getters["dashboard/screens"] || []).find(
        ({id}) => id == this.screenId
      );
    },
    isScreen() {
      return (this?.screen?.portal_data?.type || "") !== "script";
    },
    latestVersion() {
      return this?.items?.length ? this.items[0].versionId : "";
    },
    latest() {
      return this?.items?.length
        ? parseVersion(this.items[0], this.screen.path)
        : null;
    },
    referenceConnectorsIds() {
      return (this.screen.reference_connectors || []).map(({id}) => id);
    },
    connectorId() {
      return this.referenceConnectorsIds?.length
        ? this.referenceConnectorsIds[0]
        : undefined;
    },
    latestTemplate() {
      return this.$store.getters["dashboard/template"](this.screenId);
    }
  },
  methods: {
    _newDraft() {
      this.upgrade();
      this.$store.dispatch("dashboard/create", this.template);
      this.$nextTick(() => {
        let ids = getUnpublishedList(); // can not be cached
        if (ids.length) {
          let id = Math.max.apply(Math, ids) * -1;
          this.goto_path = `/dashboard/screen/edit/${id}`;
        }
        this.close();
      });
    },
    _publish() {
      var payload = JSON.parse(JSON.stringify(this.screen));
      payload.content = this.template;
      payload.reference_connectors_ids = this.referenceConnectorsIds;
      payload.revision_code = this.new_version || payload.revision_code;
      if (this.selected) {
        payload.revision_comment = this.selected.revision_comment_changed;
      }
      this.busy = true;
      let srv = new ScreenService();
      srv.save(payload).then(
        (response) => {
          this.busy = false;
          this.$store.commit("dashboard/SET_SCREEN", response);
          this.$store.commit("dashboard/SET_TEMPLATE", {
            id: response.id,
            data: this.template
          });
          this.$store.dispatch("dashboard/restoreDraft", this.screen.id);
          // this.$store.dispatch("dashboard/initDraft", this.screen.id);
          if (this.connectorId) {
            this.goto_path = `/dashboard/equipment/${this.connectorId}/${this.screen.id}`;
          } else {
            this.goto_path = `/dashboard/equipment/screen/${this.screen.id}`;
          }
          this.close();
        },
        (error) => {
          this.busy = false;
          console.log(error);
        }
      );
    },
    _overwriteDraft() {
      this.upgrade();
      this.$store.commit("dashboard/SAVE_DRAFT", {
        screenId: this.screen.id,
        template: this.template
      });
      this.$store.dispatch("dashboard/initDraft", this.screen.id);
      this.$nextTick(() => {
        this.goto_path = `/dashboard/screen/edit/${this.screen.id}`;
        this.close();
      });
    },
    resetSearch() {
      this.query = "";
      this.$set(this.interval, "user", null);
      this.$set(this.interval, "original", null);
      this.$nextTick(() => {
        this.$set(this.interval, "original", {
          startDate: moment(this.items[this.items.length - 1].date),
          endDate: moment(this.items[0].date)
        });
        if (this.$refs.query) {
          this.$refs.query.focus();
        }
      });
    },
    fetchVersions() {
      var srv = new ScreenService();
      srv.fetchVersions(this.screen.id).then(
        (resp) => {
          if (resp && resp.length) {
            var lst = resp
              .sort(
                (a, b) =>
                  new Date(b.LastModified).getTime() -
                  new Date(a.LastModified).getTime()
              )
              .map((i, ix) => ({
                ...i,
                ...{
                  seq: resp.length - ix,
                  versionId: i.VersionId,
                  date: i.LastModified,
                  status: ix == 0 ? "ready" : "not_visible",
                  revision_code: ix == 0 ? this.screen.revision_code : "",
                  revision_comment: ix == 0 ? this.screen.revision_comment : ""
                }
              }));
            this.$set(this, "items", lst);
            // expanded
            let expanded = {};
            lst.forEach(({versionId}) => {
              expanded[versionId] = false;
            });
            this.$set(this, "expanded", expanded);
            this.resetSearch();
            // this.$nextTick(() => {
            //   this.visibleItems();
            // });
          } else {
            this.error = this.$t("there_are_no_items_configured");
          }
        },
        (error) => {
          this.error = error;
        }
      );
    },
    fetchVersionsMetaData(lst) {
      let len = (lst || []).length;
      if (!len) return;
      let ts = new Date().getTime();
      let items = this.items;
      lst.forEach((ix) => {
        let item = items[ix];
        if (item.status != "not_visible") return;
        this.cid++;
        item.status = "loading";
        var url = `${this.screen.path}?versionId=${item.VersionId}&_=${ts}${ix}`;
        fetch(url, {method: "HEAD"})
          .then((response) => {
            let entry =
              (response.url &&
                this.items.find(
                  (i) =>
                    i.VersionId == this.$utils.gup("versionId", response.url)
                )) ||
              null;
            if (entry && response.ok) {
              entry.revision_code =
                response.headers.get("x-amz-meta-revision_code") || "";
              let revision_comment =
                response.headers.get("x-amz-meta-revision_comment") || "";
              entry.revision_comment = revision_comment
                ? this.$utils.utf_atob(revision_comment)
                : "";
              entry.status = "ready";
            }
            this.cid++;
          })
          .catch(() => {
            this.cid++;
          });
      });
    },
    fetchSelectedVersion() {
      var url = `${this.screen.path}?versionId=${
        this.selected.versionId
      }&_=${new Date().getTime()}`;
      this.busy = true;
      this.$http.get(url).then(
        (response) => {
          this.busy = false;
          this.$set(this, "template", JSON.parse(response.bodyText));
          if (this.selected.versionId != this.latestVersion) {
            this.$nextTick(() => {
              let el = document.getElementById(this.selected.versionId);
              if (el && !el.disabled && el.focus) {
                el.focus();
              }
            });
          }
        },
        (error) => {
          this.busy = false;
          console.log("ko");
          //resolve(null);
        }
      );
    },
    onDraftClicked() {
      warnUnsavedChanged(this, "", this._overwriteDraft);
    },
    onNewClicked() {
      this._newDraft();
    },
    onPublishClicked() {
      warnUnsavedChanged(this, "", this._publish);
    },
    select(item, edit) {
      const version = item.versionId;
      if (this.selected && this.selected.versionId == version) {
        this.onSelect(item);
        return;
      }
      let ix = this.items.findIndex(({versionId}) => versionId == version);
      if (ix > -1 && this.$refs.tbl) {
        if (edit && item.versionId != this.latestVersion) {
          let el = document.getElementById(version);
          if (el && !el.disabled) {
            el.disabled = false;
          }
        }
        this.$refs.tbl.select(ix, item);
      }
    },
    onSelect(item) {
      if (!this.list.length) return;
      this.selected = item;
      this.template = null;
      if (item) {
        let version = this.items[0].revision_code;
        if (version) {
          let v = version.split(".");
          if (v[v.length - 1]) {
            v[v.length - 1] = parseInt(v[v.length - 1]) + 1;
            version = v.join(".");
          }
        }
        if (!version) {
          version = this.items[0].seq + 1;
        }
        this.new_version = version;
        this.fetchSelectedVersion();
      }
    },
    upgrade() {
      // convert to the new render version (if needed)
      // if (this.version && this.template && !this.template.render_version) {
      //   this.template.render_version = this.version;
      //   this.template.panels.forEach((panel) => {
      //     let tpl =
      //       Panels.find((i) => i.template.template == panel.template) || null;
      //     if (tpl) {
      //       panel.heightProportion = tpl.template.heightProportion;
      //       panel.style = JSON.parse(JSON.stringify(tpl.template.style));
      //     }
      //   });
      // }
    },
    close() {
      this.$nextTick(() => {
        $(this.$refs.dlgImport).modal("hide");
      });
    },
    onIntervalChanged(value) {
      this.$set(this.interval, "user", {
        startDate: value.startDate._d.getTime(),
        endDate: value.endDate._d.getTime()
      });
    },
    toggleRevision(versionId) {
      if (versionId in this.expanded) {
        this.$delete(this, "expanded", versionId);
      } else {
        this.$set(this.expanded, versionId, true);
      }
    },
    // script related operations
    _overwriteScript() {
      this.$store
        .dispatch("scripts/validateContent", JSON.stringify(this.template))
        .then((result) => {
          if (result) {
            this.goto_path = `edit`;
            this.navigate = true;
            this.$nextTick(() => {
              this.close();
            });
          }
        });
    },
    onRestoreScriptClicked() {
      warnUnsavedChanged(this, "", this._overwriteScript);
    }
  },
  created() {
    this.fetchVersions();
  },
  mounted() {
    if (!this.screenId || !this.screen) {
      this.$emit("close");
      return;
    }
    $(this.$refs.dlgImport)
      .on("hide.bs.modal", () => {
        this.$emit("close", (this.navigate && this.goto_path) || "");
      })
      .on("show.bs.modal", () => {
        this.$emit("open");
      });
    $(this.$refs.dlgImport).modal("show");
  }
};
</script>

<style scoped>
.footer-input {
  padding: 0 12px 0 0;
  background-color: whitesmoke;
}

.footer-input > input {
  display: inline-block;
  border-color: transparent;
  border-right: 1px solid #d2d6de;
  background-color: #fff;
  margin-right: 10px;
  max-width: 60px;
  padding: 0 6px;
  text-align: center;
}
.modal-body {
  text-align: left;
  z-index: 1;
}
.modal-footer {
  z-index: 0;
}

.overlay-local {
  position: absolute;
  top: 100px;
  left: 50%;
  font-size: 25pt;
  opacity: 0.5;
  z-index: 1;
}

.icon-loading {
  padding: 20px;
  font-size: 18pt;
  color: #999;
  cursor: wait;
}

.anchor {
  margin-left: 5px;
  color: #3c8dbc;
}

.anchor:hover {
  color: #333;
}

span > a > i.fa-hover {
  color: transparent;
  margin-left: 5px;
}

span:hover > a > i.fa-hover {
  color: inherit;
  font-weight: 600;
}

div > i.fa-hover {
  color: #999;
  margin-right: 5px;
}

div:hover > i.fa-hover {
  color: inherit;
}

.screen-name {
  white-space: nowrap;
  text-align: left;
  font-weight: 600;
  font-size: 16pt;
  padding: 5px;
  border-radius: 5px 5px 0 0;
  margin-top: -10px;
}

.box-body {
  padding: 0;
}

.disabled-info-box {
  max-width: 600px;
  overflow-y: auto;
  background-color: whitesmoke;
  padding: 2px;
  border-radius: 3px;
  margin-bottom: 5px;
  min-height: 20px;
}

.dl-horizontal {
  margin-bottom: 0;
}
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.disabled:hover {
  cursor: not-allowed;
}

.result-header {
  color: #555;
  font-weight: 600;
  background-color: #e1e1e1;
  padding: 4px;
  border-radius: 5px 5px 0 0;
}

.result-table {
  max-height: 350px;
  min-height: 250px;
  overflow-y: auto;
  text-align: left;
}

.sidebar-form {
  display: block !important;
  overflow: visible;
  background-color: #fff;
  margin: 10px 0;
}

.table-striped > tbody > tr.expanded-row:nth-of-type(odd),
tr.expanded-row {
  background-color: transparent;
}

tr.expanded-row > td > div {
  position: relative;
  min-height: 60px;
  margin: -5px;
  padding: 20px 10px 10px 10px;
  border: 1px solid #d2d6de;
  border-radius: 3px;
  overflow: hidden;
  resize: vertical;
}

tr.expanded-row > td > div > div.title {
  position: absolute;
  color: #666;
  left: 10px;
  top: -2px;
  background-color: transparent;
  font-size: 12pt;
  font-weight: 600;
}

tr.expanded-row > td > div > span.close-btn {
  position: absolute;
  color: #666;
  right: 5px;
  top: 5px;
  font-size: inherit;
}

tr.expanded-row > td > div > div.body {
  position: relative;
  margin-top: 5px;
  height: 100%;
  min-height: 50px;
}

tr.expanded-row > td > div > div.body > textarea {
  padding: 4px 6px;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  border: 0;
  border-radius: 5px;
  top: 0;
  right: 0;
  left: 0;
  resize: none;
}

textarea:disabled {
  background: whitesmoke;
  cursor: not-allowed;
}

tr.expanded-row > td > div > div.body > textarea:focus {
  border: 0;
  outline-width: 0;
}

.revision-column {
  overflow: hidden;
  position: relative;
}

.revision-column > div.revision-column-content {
  display: inline-block;
  width: 95%;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 5px;
  padding-left: 5px;
}

.revision-column > div.revision-column-expand {
  display: inline-block;
  width: 5%;
  vertical-align: top;
}

@media (min-width: 768px) {
  .sidebar-mini.sidebar-collapse .sidebar-form {
    display: block !important;
    overflow: visible;
    background-color: #fff;
    z-index: 9999;
    margin: 10px 0;
  }
}
</style>
