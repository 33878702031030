<template>
  <portal to="contextmenu" v-if="floatPanel.open">
    <FloatPanel
      class="float-panel"
      :draggable="true"
      :handleSelf="true"
      :defaultPosition="floatPanel.rect"
      :open="floatPanel.open"
      title="custom_page"
      @update:open="onFloatPanelUpdate($event)"
      @dragstart="floatPanel.dragging = true"
      @dragend="onDragEnd(floatPanel, $event)"
    >
      <div class="popup">
        <div class="popup-body">
          <JsScriptEditor
            v-model="iValue"
            @save="$emit('save')"
            language="html"
          />
        </div>
      </div>
    </FloatPanel>
  </portal>
</template>

<script>
import JsScriptEditor from "./index.vue";
import FloatPanel from "@/components/editor/float-panel.vue";
const _fpsize = [800, 500];
let _fp = {
  w: _fpsize[0],
  h: _fpsize[1],
  x: parseInt((window.innerWidth - _fpsize[0]) / 2),
  y: 70
};
_fp.x = _fp.x < 0 ? 0 : _fp.x;
export default {
  name: "HTMLEditor",
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    }
  },
  components: {
    FloatPanel,
    JsScriptEditor
  },
  data() {
    return {
      floatPanel: {
        rect: {
          top: `${_fp.y}px`,
          left: `${_fp.x}px`
        },
        open: false,
        dragging: false
      }
    };
  },
  computed: {
    iValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    onDragEnd(floatPanel, $event) {
      floatPanel.top = $event.top;
      floatPanel.left = $event.left;
      floatPanel.dragging = false;
    },
    onFloatPanelUpdate(e) {
      this.$emit(e ? "open" : "close");
    }
  },
  mounted() {
    this.floatPanel.open = true;
  },
  beforeDestroy() {
    this.floatPanel.open = false;
  }
};
</script>

<style scoped>
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.popup {
  position: relative;
  height: 100%;
}

.popup > .popup-body {
  display: flex;
  background-color: transparent;
  /* border: 1px solid lightgray; */
  border-width: 0;
  margin: 0;
  resize: both;
  overflow: auto;
  height: calc(100vh - 40vh);
  min-height: 200px;
  max-height: calc(100vh - 200px);
}

.popup > .popup-body > div {
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  min-height: 100%;
  height: 100%;
}

.js-editor-toolbar {
  position: absolute;
  right: 25px;
  top: -22px;
  color: gray;
}

.file-info {
  color: #666;
}
</style>
